import { $ } from '@situation/setdesign.util';

let eventsWithAvailability = [];
$(document).one('TicketCalendar.init', (e, TicketCalendar, $on) => {
    $on(TicketCalendar.FILTER_DEFAULTS, (defaults) => {
        defaults.update({
            theme: 'suffs-theme',
            'disable-default-analytics': true,
            'accordion-month-header': { arrowSvgName: 'icon-arrow' },
            'vue-parent': '.js-ticket-calendar-wrap',
        });
    });

    TicketCalendar.api.events().done((events) => {
        eventsWithAvailability = events.filter((event) => event.availability !== 'default');
    });

    $on('fc-view-render', (view, element) => {
        eventsWithAvailability.forEach((event) => {
            const eventDate = event.date.split(' ')[0];
            element
                .find(`.fc-day[data-date="${eventDate}"]`)
                .addClass(`has-availability has-availability--${event.availability}`);
        });
    });
});
